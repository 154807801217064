<template>
  <SuiModal v-model="dialog">
    <SuiModalContent style="height: 530px; display: flex; flex-direction:column">
      <a>
        <i
          @click="close()"
          style="text-decoration:none !important; cursor: pointer;font-size: 25px;
          position: sticky; left: 105%; top: 10px; margin-right: -5px; margin-top: -5px;"
          class="times icon general-blue-color">
        </i>
      </a>
      <SuiModalDescription style="width: 100%; padding-right:15px; padding-left:15px; display: flex; flex-direction: column;">
        <h1 v-if="isNewBulletin" style="margin-top: -15px">{{  $t('surveys.newSurvey') }}</h1>
        <h1 v-else style="margin-top: -15px">{{edit_data.title}}</h1>

        <form
          class="ui form fields"
          :class="{'blue medium double loading':isLoadingSave}"
          style="width: 100%; height: 80%; display: flex"
        >
          <div class="left" style="width: 50%; height: 100%">

            <div class="title_field" style="width: 100%; justify-content: space-between; margin-bottom: 8px">
              <label style="font-size: 16px; color: #323232;">{{  $t('surveys.title') }}</label>
              <div class="ui input" style="width: 85%">
                <input  type="text" maxlength="25" :placeholder="$t('surveys.title')" v-model="titleOfBulletin">
              </div>
            </div>

            <div class="description_field" style="width: 100%">
                <div class="ui input" style="width: 100%">
                  <textarea
                    style="border: 1px solid rgba(34,36,38,.15); resize:none; width: 100%"
                   maxlength="70" :placeholder="$t('surveys.question')" rows="4" v-model="descriptionOfBulletin"/>
                </div>
            </div>

            <div v-if="!isNewBulletin" class="creationDate">
    <div class="date" style="width: 55%; display: flex; align-items: center ">
      <label style="font-size: 16px; color: #323232">{{  $t('surveys.yes') }}</label>
      <span style="padding-left: 22px">{{form.yes}}</span>
    </div>
  </div>

  <div v-if="!isNewBulletin" class="creationDate">
    <div class="date" style="width: 55%; display: flex; align-items: center ">
      <label style="font-size: 16px; color: #323232">{{  $t('surveys.no') }}</label>
      <span style="padding-left: 15px">{{form.no}}</span>
    </div>
  </div>

  <div v-if="!isNewBulletin" class="creationDate">
    <div class="date" style="width: 55%; display: flex; align-items: center">
      <label style="font-size: 16px; color: #323232">{{  $t('surveys.totalResponses') }}</label>
      <span style="padding-left: 15px">{{form.totalResponses}}</span>
    </div>
  </div>

          </div>
          <div class="right" style="width: 50%; height: 100%; display: flex; align-items: center">

<div
  class="right-content"
  style="height: 50%; width: 100%; padding-left: 40px"
  :style="isNewBulletin ? 'margin-top: -35px' : 'margin-top: -75px'">

  <div class="publicationDate">
    <div
      class="date"
      style="width: 55%; display: flex; align-items: center">
      <label style="font-size: 16px; color: #323232; margin-right: 10px">{{  $t('board.headers.publication') }}</label>
      <Datepicker
        style="
        --vdp-bg-color: #f2f2f2; --vdp-hover-bg-color: #88c6df;
        --vdp-selected-bg-color: #436f98; text-align: center; max-width: 110px"
        inputFormat='dd/MM/yyyy'
        :lowerLimit="nowDate"
        :locale="getLanguage()"
        :typeable="false"
        v-model="form.activatedAt"
      />
    </div>
    <div class="hour" style="width: 45%; display: flex; justify-content: space-between; align-items: center">
      <label style="font-size: 16px; color: #323232; padding-left: 10px; margin-right: 5px">{{  $t('board.time') }}</label>
      <div class="mb-2" style="display: flex; flex-direction: row;
       justify-content: flex-end !important; margin-top: 10px">
        <SuiDropdown
          style="min-width: 20px !important; max-width: 20px !important"
          selection
          v-model="form.activationHour"
          placeholder="00"
          :options="hours"
        />
        <SuiDropdown
          style="min-width: 20px !important; max-width: 20px !important"
          selection
          v-model="form.activationHourPeriod"
          placeholder="AM"
          :options="['AM', 'PM']"
        />
      </div>
    </div>
  </div>

  <div class="expirationDate">
    <div class="date" style="width: 55%; display: flex; align-items: center">
      <label style="font-size: 16px; color: #323232; margin-right: 17px">{{  $t('board.expiration') }}</label>
      <Datepicker
        style="
        --vdp-bg-color: #f2f2f2; --vdp-hover-bg-color: #88c6df;
        --vdp-selected-bg-color: #436f98; text-align: center; max-width: 110px"
        inputFormat='dd/MM/yyyy'
        :lowerLimit="nowDate"
        :locale="getLanguage()"
        :typeable="false"
        v-model="form.expiresAt"
      />
    </div>
    <div class="hour" style="width: 45%; display: flex; justify-content: space-between; align-items: center">
      <label style="font-size: 16px; color: #323232; padding-left: 10px; margin-right: 5px">{{  $t('board.time') }}</label>
      <div class="mb-2" style="display: flex; flex-direction: row;  justify-content: flex-end !important">
        <SuiDropdown
          style="min-width: 20px !important; max-width: 20px !important"
          selection
          v-model="form.expiresHour"
          placeholder="00"
          :options="hours"
        />
        <SuiDropdown
          style="min-width: 20px !important; max-width: 20px !important"
          selection
          v-model="form.expiresHourPeriod"
          placeholder="AM"
          :options="['AM', 'PM']"
        />
      </div>
    </div>
  </div>

  <div
    class="isImportant_and_isActive"
    style="width: 100%; display:flex; align-items: center"
  >
    <div style="
      display: flex; flex-direction: column; justify-content: center;
      width: 50%; height: 100%; justify-content: space-between; padding-top: 10px;
      align-items: center; padding-left: -35px"
    >
      <label
        style="margin-bottom: 10px;color: #323232;">
      {{  $t('board.headers.active') }}
      </label>
      <div
      class="ui toggle checkbox"
      style="transform: scale(1.3)"
      >
        <input type="checkbox" :v-model="form.isActive" :checked="form.isActive" @change="changeActivation()">
        <label style="height: 0px; width:0px"></label>
      </div>
      <span style="color: #323232;">{{ form.isActive ?  $t('yes')  :  $t('no') }}</span>
    </div>
  </div>

</div>

</div>

        </form>

        <div style="margin-top: 360px ;align-self:center; position: absolute" v-if="(v$.form.title.$error || v$.form.expiresHourPeriod.$error || v$.form.expiresAt.$error || v$.form.expiresHour.$error)">
          <span class="text-danger"  v-if="v$.form.title.$error">
            {{v$.form.title.$errors[0].$message }}
          </span>
          <span v-else-if="v$.form.expiresAt.$error" class="text-danger">
            {{ v$.form.expiresAt.$errors[0].$message}}
          </span>
          <span v-else-if="v$.form.expiresHour.$error" class="text-danger">
            {{ v$.form.expiresHour.$errors[0].$message}}
          </span>
          <span v-else-if="v$.form.expiresHourPeriod.$error" class="text-danger">
            {{v$.form.expiresHourPeriod.$errors[0].$message }}
          </span>
          <!-- <span v-else-if="error" class="text-danger">{{$t('errors.emailTaken')}}</span> -->
        </div>

        <div class="btns" :class="{'disabled':isLoadingSave}">
          <Button v-if="!isNewBulletin"
            :appearance="classBtnObject"
            class="delete"
            :text="$t('board.delete')"
            color="third-color"
            @clicked="deleteSurvey"
            :class="{'disabled':isLoadingSave}"
          />
          <Button v-if="!isNewBulletin"
            :appearance="classBtnObject"
            class="save"
            :text="$t('board.save')"
            color="primary"
            @clicked="saveChanges"
            :class="{'disabled':isLoadingSave}"
          />
          <Button v-else
            class="d-inline"
            :appearance="classBtnObject"
            :text="$t('board.create')"
            color="primary"
            @clicked="createSurvey"
            :class="{'disabled':isLoadingSave}"
          />
        </div>
      </SuiModalDescription>
    </SuiModalContent>
  </SuiModal>
</template>

<script>
// SuiDropdown
import { SuiModal, SuiModalContent, SuiModalDescription, SuiDropdown } from 'vue-fomantic-ui'
import Button from '@/components/form/Button'
import {
  createSurvey,
  deleteSurvey as deleteBltn,
  updateSurvey
} from '@/api/surveys'
import Datepicker from 'vue3-datepicker'
import { mapActions, mapGetters } from 'vuex'
import { es, en } from 'date-fns/locale'
import i18n from '@/plugins/i18n'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators/dist/raw.esm'

export default {
  props: {
    show: {
      type: Boolean,
      required: false,
      default: true
    },
    create: {
      type: Boolean,
      required: false,
      default: true
    },
    edit_data: {
      type: Object,
      required: false
    }
  },
  components: {
    SuiModal,
    SuiModalContent,
    SuiModalDescription,
    Button,
    SuiDropdown,
    Datepicker
    // SuiDropdown

  },
  emits: ['close'],
  setup: () => ({ v$: useVuelidate() }),
  data () {
    return {
      form: {
        title: '',
        description: '',
        createdAt: '',
        creationHour: null,
        isActive: false,
        expiresAt: null,
        expiresHour: null,
        expiresHourPeriod: null,
        activatedAt: null,
        activationHour: null,
        activationHourPeriod: null,
        yes: null,
        no: null,
        totalResponses: null
      },
      isLoadingSave: false,
      hours: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      nowDate: new Date()
    }
  },

  validations () {
    return {
      form: {
        title: {
          required: helpers.withMessage(this.$t('errors.surveys.must_have_title'), required)
        },
        expiresAt: {
          required: helpers.withMessage(this.$t('errors.surveys.expirationDate'), required)
        },
        expiresHour: {
          required: helpers.withMessage(this.$t('errors.surveys.expirationTime'), required)
        },
        expiresHourPeriod: {
          required: helpers.withMessage(this.$t('errors.surveys.chooseAMPM'), required)
        }
      }
    }
  },

  updated () {
    this.clearForm()
    // console.log('iuehfiuehfuiehfu')
    if (!this.create) {
      // console.log('entre', this.stats)
      // console.log('Incoming values of bulletin already created --->', this.edit_data)
      this.form.title = this.edit_data.title
      this.form.description = this.edit_data.description
      // setting Dates

      // Creation
      // const creationDate = new Date(this.edit_data.createdAt)
      // Activation
      if (this.edit_data.activatedAt) {
        // const activationDate = new Date(this.edit_data.activatedAt)
        const activationDate = new Date(this.edit_data.activatedAt.replace('Z', ''))
        // --------------------------------------------------------------------
        // ESTO DEBE QUITARSE EN EL SIGUIENTE AJUSTE DE HORAS Y FECHAS
        // activationDate.setHours(activationDate.getHours() - 1)
        // --------------------------------------------------------------------
        this.form.activatedAt = activationDate
        let activationHour = activationDate.getHours()
        this.form.activationHourPeriod = activationHour >= 12 ? 'PM' : 'AM'
        activationHour = activationHour > 12 ? activationHour - 12 : (activationHour === 0 ? 12 : activationHour)
        activationHour = activationHour < 10 ? `0${activationHour}` : `${activationHour}`
        this.form.activationHour = `${activationHour}`
      }

      // Expiration
      if (this.edit_data.expiresAt) {
        // const expirationDate = new Date(this.edit_data.expiresAt)
        const expirationDate = new Date(this.edit_data.expiresAt.replace('Z', ''))
        // --------------------------------------------------------------------
        // ESTO DEBE QUITARSE EN EL SIGUIENTE AJUSTE DE HORAS Y FECHAS
        // expirationDate.setHours(expirationDate.getHours() - 1)
        // --------------------------------------------------------------------
        this.form.expiresAt = expirationDate
        let expirationHour = expirationDate.getHours()
        this.form.expiresHourPeriod = expirationHour >= 12 ? 'PM' : 'AM'
        expirationHour = expirationHour > 12 ? expirationHour - 12 : (expirationHour === 0 ? 12 : expirationHour)
        expirationHour = expirationHour < 10 ? `0${expirationHour}` : `${expirationHour}`
        this.form.expiresHour = `${expirationHour}`
      }
      this.form.yes = this.stats.yes
      this.form.totalResponses = this.stats.totalResponses
      this.form.no = this.stats.no
      this.form.isActive = this.edit_data.active
    } else {
      this.form.activatedAt = this.nowDate
      let nowHour = this.nowDate.getHours()
      nowHour = nowHour > 12 ? nowHour - 12 : (nowHour === 0 ? 12 : nowHour)
      nowHour = nowHour < 10 ? `0${nowHour}` : `${nowHour}`
      this.form.activationHour = `${nowHour}`
      this.form.activationHourPeriod = this.nowDate.getHours() >= 12 ? 'PM' : 'AM'
    }
  },
  computed: {

    ...mapGetters('bulletinBoard', [
      'allBulletins'
    ]),
    ...mapGetters('surveys', [
      'stats'
    ]),

    classBtnObject () {
      return {
        height: '29px',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        padding: '7px 2.4rem !important'
      }
    },

    es () {
      return es
    },
    en () {
      return en
    },

    expiresAt () {
      return `${this.form.expiresHour}:00:00 ${this.form.expiresHourPeriod}`
    },

    residential () {
      return this.$store.getters['residential/activeResidential']
    },
    dialog () {
      return this.show
    },
    isNewBulletin () {
      return Object.keys(this.edit_data).length === 0
    },
    titleOfBulletin: {
      get () {
        return this.form.title
      },
      set (newVal) {
        this.form.title = newVal
      }
    },

    descriptionOfBulletin: {
      get () {
        return this.form.description
      },
      set (newVal) {
        this.form.description = newVal
      }
    }
  },
  methods: {
    ...mapActions('loading', ['setLoading']),
    ...mapActions('surveys', ['stats', 'addNewSurvey', 'delete_Survey', 'update_Survey', 'loadSurveys']),

    getLanguage () {
      if (i18n.global.locale === 'en') {
        return en
      } else {
        return es
      }
    },
    changeActivation () {
      this.form.isActive = !this.form.isActive
    },

    classObjectFiles () {
      return {
        width: this.isNewBulletin ? '100%' : '60%'
      }
    },

    close () {
      this.$emit('close')
      this.clearForm()
      this.v$.$reset()
    },

    clearForm () {
      this.form = {
        title: '',
        description: '',
        createdAt: '',
        isActive: false,
        expiresAt: null,
        expiresHour: null,
        expiresHourPeriod: '',
        activatedAt: null,
        activationHour: null,
        activationHourPeriod: null
      }
    },

    async createSurvey () {
      this.v$.$validate()
      const formValid = !(this.v$.$error)

      if (formValid) {
        // this.isLoadingSave = true
        this.setLoading(true)

        const data = {
          name: this.form.title,
          description: this.form.description,
          expiresAt: this.getCompleteDate(this.form.expiresHour, this.form.expiresHourPeriod, this.form.expiresAt),
          activatedAt: this.getCompleteDate(this.form.activationHour, this.form.activationHourPeriod, this.form.activatedAt),
          status: this.form.isActive ? 'ACTIVE' : 'INACTIVE',
          typeId: 1,
          residentialId: `${this.residential.residentialId}`
        }
        // for (const pair of data.entries()) {
        //   console.log(pair[0], pair[1])
        // }

        createSurvey(data).then((r) => {
          // console.log('ENTRE')
          const bulletin = r.data
          // console.log('created bulletin response from server ---->', {
          //   id: bulletin.id,
          //   title: bulletin.name,
          //   description: bulletin.description,
          //   activatedAt: bulletin.activatedAt,
          //   expiresAt: bulletin.expiresAt,
          //   active: bulletin.status === 'ACTIVE'
          // })
          this.addNewSurvey({
            id: bulletin.id,
            title: bulletin.name,
            description: bulletin.description,
            activatedAt: bulletin.activatedAt,
            expiresAt: bulletin.expiresAt,
            active: bulletin.status === 'ACTIVE'
          })
          // console.log(r)
          // this.isLoadingSave = false
          this.close()
          this.$swal(this.$t('surveys.createdSuccess'), '', 'success')
        }).catch((error) => {
          // this.isLoadingSave = false
          if (Array.isArray(error.response.data.message)) {
            this.$swal(error.response.data.message[0], '', 'error')
          } else {
            this.$swal(error.response.data.message, '', 'error')
          }
        }).finally(() => {
          this.setLoading(false)
        })
      }
    },

    getCompleteDate (hourStr, period, date) {
      const hourInt = parseInt(hourStr)
      let hourFormatted
      if (period === 'AM') {
        hourFormatted = hourInt === 12 ? 0 : hourInt
      } else {
        hourFormatted = hourInt === 12 ? 12 : 12 + hourInt
      }

      hourFormatted = hourFormatted < 10 ? `0${hourFormatted}` : `${hourFormatted}`
      const monthFormated = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`
      const dayFormated = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`
      // console.log('oihiudhiuev', hourFormatted < 10, hourFormatted)
      // next change for hour change (UTC refactoring)
      const completeDateExpiration = `${date.getFullYear()}-${monthFormated}-${dayFormated}T${hourFormatted}:00:00`
      // console.log(completeDateExpiration)
      // hot fix (production preventive crash)
      // const completeDateExpiration = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${hourFormatted}:00:00`
      return completeDateExpiration
    },

    async saveChanges () {
      // this.isLoadingSave = true
      this.v$.$validate()
      const formValid = !(this.v$.$error)
      // console.log('SAVING CHANGES OF EXISTING BULLETIN')

      if (formValid) {
        this.setLoading(true)

        const data = {
          name: this.form.title,
          description: this.form.description,
          expiresAt: this.getCompleteDate(this.form.expiresHour, this.form.expiresHourPeriod, this.form.expiresAt),
          activatedAt: this.getCompleteDate(this.form.activationHour, this.form.activationHourPeriod, this.form.activatedAt),
          status: this.form.isActive ? 'ACTIVE' : 'INACTIVE'
        }

        if (this.create) {
          data.residentialId = `${this.residential.residentialId}`
          data.append('residentialId', `${this.residential.residentialId}`)
        }

        updateSurvey(this.edit_data.id, data).then((r) => {
          const bulletin = r.data
          // console.log('irngrvjinnbjr', {
          //   id: bulletin.id,
          //   title: bulletin.name,
          //   description: bulletin.description,
          //   activatedAt: bulletin.activatedAt,
          //   expiresAt: bulletin.expiresAt,
          //   active: bulletin.status === 'ACTIVE'
          // })
          // console.log('bulletin updated response from server --->', bulletin)
          this.update_Survey({
            id: bulletin.id,
            title: bulletin.name,
            description: bulletin.description,
            activatedAt: bulletin.activatedAt,
            expiresAt: bulletin.expiresAt,
            active: bulletin.status === 'ACTIVE'
          })

          // console.log(bulletin)
          // console.log(this.edit_data)
          // this.isLoadingSave = false
          this.close()
          this.$swal(this.$t('surveys.savedSuccess'), '', 'success')
        }).catch((error) => {
          // this.isLoadingSave = false
          if (Array.isArray(error.response.data.message)) {
            this.$swal(error.response.data.message[0], '', 'error')
          } else {
            this.$swal(error.response.data.message, '', 'error')
          }
        }).finally(() => {
          this.setLoading(false)
        })
      }

      // console.log('activationDate', completeDateActivation)
      // console.log('expiresAt', completeDateExpiration)
    },

    async deleteSurvey () {
      this.$swal({
        title: this.$t('surveys.wantToDeleteAnnouncement'),
        showCancelButton: true,
        confirmButtonText: this.$t('yes'),
        confirmButtonColor: '#dc3545',
        cancelButtonText: this.$t('no'),
        cancelButtonColor: '#2186b0'
      }).then((result) => {
        if (result.isConfirmed) {
          // this.isLoadingSave = true
          this.setLoading(true)
          deleteBltn(this.edit_data.id).then(r => {
            this.delete_Survey(this.edit_data)
            /* console.log('data deleted')
            console.log(r)
            console.log('data in edit data')
            console.log(this.edit_data) */
            // this.isLoadingSave = false
            this.close()
            this.$swal(this.$t('surveys.deletedSuccess'), '', 'success')
          }).catch(() => {
            // this.isLoadingSave = false
            this.$swal(this.$t('errors.surveys.deletingError'), '', 'error')
          }).finally(() => {
            this.setLoading(false)
          })
        }
      })
    }

  }
}
</script>

<style scoped>
  h1 {
      color: #2c3e50 !important;
      font-family: inherit !important;
      }
  .btns {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  textarea:focus {
    border-color: #85b7d9 !important;
  }
  textarea::placeholder {
    color: rgba(34,36,38,.15)
  }
  textarea:focus::placeholder {
    color: rgb(151, 144, 144);
  }

  .title_field, .coverPhoto_field, .date_field, .description_field {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .description_existing_bulletin {
    margin-top: 30px
  }

    .importantThumbtack, .nonImportantThumbtack {
      color: #808080;
      font-size: 20px;
    }

    .importantThumbtack {
      color: rgb(235, 36, 36);
      /* transform: rotate(-45deg); */
      transform: rotate(0deg);
      transition: ease .3s
    }

    /* .importantThumbtack:hover {
      color: #808080;
      transform: rotate(-45deg)
    } */

    .nonImportantThumbtack {
      color: #808080;
      transform: rotate(-45deg);
      transition: ease .3s
    }

    /* .nonImportantThumbtack:hover {
      color: rgb(235, 36, 36);
      transform: rotate(0);
    } */
    .delete, .save {
      /* height: 40px !important;*/
      text-transform: none !important;
      letter-spacing: normal !important;
      border-radius: 5px !important;
      transition: background-color .5s ease !important;
      border: none !important;
      width: 10rem !important;
      font-family: inherit;
    }
  .delete {
    margin-right: 10px !important;
  }

  .save {
    margin-left: 10px !important;
  }

  .creationDate, .publicationDate, .expirationDate {
    width: 100%;
    display: flex;
    align-items: center
  }

  .creationDate{
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  .disabled {
    pointer-events: none;
    opacity: 0.4;
}
</style>
