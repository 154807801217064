<template>
  <div class="justify-content-start view-container">
    <HeaderResidential />
  </div>
  <div class="justify-content-start w-100 view-container" style="margin-bottom: 50px">
    <div class="create_and_changeSection_btns">
      <div></div>
      <!-- <Button v-if="!loading"
        :appearance="classObjectCreateSurvey"
        text="Crear anuncio"
        color="primary"
        @clicked="openEditModal({})"
      /> -->

      <div class="changeSection">
        <div style="width: 50% !important; display: flex; justify-content: center; align-items: center">
          <Button v-if="!loading"
            :appearance="classObjectSection"
            :text="$t('surveys.new')"
            :color="'primary'"
            @click="() => {openCreateModal()}"
          />
        </div>
      </div>
    </div>

    <div
      style="width: 100%; padding-right: 35px; ; margin-bottom: 100px"
    >
      <table v-if="!loading" class="ui blue unstackable selectable celled table"
       style="cursor: default !important; color: #808080; table-layout: fixed">
       <colgroup>
          <col style="width: 6%;" />
          <col style="width: 40%;" />
          <col style="width: 30%;" />
          <col style="width: 12%;" />
          <col style="width: 12%;" />
        </colgroup>
        <thead>
        <tr>
          <th v-for="header of surveyHeaders" :key="header"
          style="text-align: center; color: #808080"
          :class="{'one wide':(header==='Activo' || header==='Destacado' || header==='')}">
            <div>
              {{header}}
            </div>
          </th>
        </tr>
        </thead>
        <tbody v-if="surveysExist">
        <tr v-for="survey in surveysData" :key="survey.id" :class="classObjectIsImportantRow(survey.important)">
          <td v-for="(value, field) in {
            id: survey.id,
            name: survey.title,
            activatedAt: survey.activatedAt,
            edit: '',
            active: survey.active,
          }"
            :key="field"
            :class="{'CoverPicture':field === 'imageUrl' && value}"
            @click="(field === 'description' || field === 'type' || field === 'name' || field === 'apartment') ? openEditModal(survey):''"
          >
          <div v-if="field === 'name' && value" class="noCoverPicture elementInTD" style="font-size: 14px">
              {{ value }}
            </div>
            <div v-else-if="field === 'activatedAt' && value" class="noCoverPicture elementInTD" style="font-size: 14px">
              {{ getFormattedCreatedDate(value) }}
            </div>
            <div v-else-if="field === 'yes' && value" class="noCoverPicture elementInTD" style="font-size: 14px">
              {{ value }}
            </div>
            <div v-else-if="field === 'no' && value" class="noCoverPicture elementInTD" style="font-size: 14px">
              {{ value }}
            </div>
            <div v-else-if="field === 'activatedAt' && !value" class="noCoverPicture elementInTD" style="font-size: 14px">
              {{$t('pqrs.noDate')}}
            </div>
            <div v-else-if="field === 'active'" class="ui toggle checkbox pqrs" style="width: 100%; display: flex; justify-content: center;" @click="changeActivation(survey)">
              <input type="checkbox" :v-model="survey.active" :checked="survey.active" @change="changeActivation(survey, $event)">
              <label style="height: 0px; width:0px; margin-top: 7px"></label>
            </div>
            <div v-else-if="field === 'edit'" class="elementInTD">
              <i class="pen icon editPen" @click="openEditModal(survey)"></i>
            </div>
            <div v-else>
              {{ value }}
            </div>
          </td>
        </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="10" class="text-center">
              {{$t('errors.surveys.noSurveys')}}
            </td>
          </tr>
        </tbody>

        <tfoot v-if="surveysExist" style="color: #808080;">
          <tr>
            <th colspan="10" style="border: none">
              <div class="ui right floated pagination menu">
                <a class="icon item" style="color: #808080;" @click="prevPageOfSurveys">
                  <i class="left chevron icon"></i>
                </a>
                <a
                  :class="classActivationStatusPage(numPagOfSurveys)"
                  class="item" style="color: #808080"
                  v-for="numPagOfSurveys in numberOfPagesOfSurveysInSection"
                  :key="numPagOfSurveys"
                  @click="changePageOfSurveys(numPagOfSurveys)"
                  >{{numPagOfSurveys}}</a>
                <a class="icon item" @click="nextPageOfSurveys">
                  <i class="right chevron icon" style="color: #808080;"></i>
                </a>
              </div>
            </th>
        </tr>
      </tfoot>
      </table>
    </div>
    <SurveysModal
      :show="isEditingSurvey"
      :create="create"
      :edit_data="modalData"
      @close="closeModal"
    />
  </div>
</template>

<script>

// import { SuiModal, SuiModalContent, SuiModaldescription } from 'vue-fomantic-ui'
import HeaderResidential from '@/components/ui/HeaderResidential'
import SurveysModal from '@/components/modals/SurveysModal'
import Button from '@/components/form/Button'
import { mapActions, mapGetters } from 'vuex'
import { changeActivationStatus } from '@/api/surveys'
// import { SuiCheckbox } from 'vue-fomantic-ui'

export default {
  components: {
    HeaderResidential,
    SurveysModal,
    Button
    // SuiCheckbox
  },
  // created () {
  //   if (this.items) {
  //     this.items = []
  //     // console.log('there are items')
  //   }
  // },
  beforeMount () {
    this.loading = true
    this.setLoading(true)
    this.loadSurveys(this.residential.residentialId)
      .then((r) => {
        // this.loading = false
        // console.log('bulletins loaded')
      }).catch((error) => {
        // this.loading = false
        console.log('error cargando pqrs', error)
      }).finally(() => {
        this.setLoading(false)
        this.loading = false
      })
  },
  // updated () {
  //   console.log('current page of bulletin is ', this.currentPageOfSurveys)
  // },
  data () {
    return {
      loading: false,
      isEditingSurvey: false,
      isShowingCoverPic: false,
      surveyHeaders: [
        this.$t('surveys.id'),
        this.$t('surveys.question'),
        this.$t('surveys.publication'),
        this.$t('surveys.edit'),
        this.$t('surveys.active')
      ],
      modalData: {},
      coverPicModalData: null,
      isOpen: true,
      maxSurveysPerPageOfSurveys: 10,
      currentPageOfSurveys: 1,
      create: false
    }
  },

  computed: {

    numberOfSurveysInSection () {
      return this.allSurveys.length
    },

    numberOfPagesOfSurveysInSection () {
      if (this.numberOfSurveysInSection <= this.maxSurveysPerPageOfSurveys) {
        return 1
      }
      return Math.ceil(this.numberOfSurveysInSection / this.maxSurveysPerPageOfSurveys)
    },

    surveysExist () {
      // console.log('Exist?', this.surveysData.length)
      // console.log('Loading?', this.loading)
      return this.surveysData.length !== 0
    },

    residential () {
      return this.$store.getters['residential/activeResidential']
    },

    surveysData () {
      let surveysToShow = this.allSurveys
      // getting 2 arrays, important and the others
      const importantSurveys = []; const nonimportantSurveys = []

      surveysToShow.forEach(survey => {
        // console.log('first forEach loop', survey)
        if (survey.active) {
          importantSurveys.push(survey)
        } else {
          nonimportantSurveys.push(survey)
        }
      })

      // Sorting survey visually by date
      importantSurveys.sort(function (surveyA, surveyB) {
        const dateA = new Date(surveyA.activatedAt)
        const dateB = new Date(surveyB.activatedAt)
        // console.log(dateA, dateB)
        return dateB - dateA
      })

      nonimportantSurveys.sort(function (surveyA, surveyB) {
        const dateA = new Date(surveyA.activatedAt)
        const dateB = new Date(surveyB.activatedAt)
        // console.log(dateA, dateB)
        return dateB - dateA
      })

      // console.log(importantSurveys, nonimportantSurveys)
      importantSurveys.push(...nonimportantSurveys)
      surveysToShow = importantSurveys

      // console.log(surveysToShow)

      const lowerIdx = this.maxSurveysPerPageOfSurveys * (this.currentPageOfSurveys - 1)
      const upperIdx = this.currentPageOfSurveys < this.numberOfPagesOfSurveysInSection ? lowerIdx + this.maxSurveysPerPageOfSurveys : this.numberOfSurveysInSection
      // console.log(lowerIdx, upperIdx, surveysToShow)
      return surveysToShow.slice(lowerIdx, upperIdx)
    },

    classObjectCreateSurvey () {
      return {
        /* 'margin-top': '25px',
        'margin-bottom': '20px', */
        height: '29px',
        with: '100px',
        display: 'flex',
        'justify-content': 'space-between',
        'align-items': 'center'
        /* padding: '7px 2.4rem !important' */
      }
    },

    classObjectSection () {
      return {
        height: '29px !important',
        width: '100% !important',
        display: 'flex',
        'justify-content': 'space-between',
        'align-items': 'center'
      }
    },

    ...mapGetters('surveys', [
      'allSurveys'
    ])
  },
  methods: {
    ...mapActions('loading', ['setLoading']),
    ...mapActions('messaging', ['setResidentialMembersToMessage']),

    sendMessage (resident) {
      this.setResidentialMembersToMessage([resident])
    },
    classActivationStatusPage (numPagOfSurveys) {
      return {
        activePage: numPagOfSurveys === this.currentPageOfSurveys
      }
    },

    prevPageOfSurveys () {
      if (this.currentPageOfSurveys !== 1) {
        this.currentPageOfSurveys -= 1
      }
    },

    nextPageOfSurveys () {
      if (this.currentPageOfSurveys !== this.numberOfPagesOfSurveysInSection) {
        this.currentPageOfSurveys += 1
      }
    },

    changePageOfSurveys (numPagOfSurveys) {
      this.currentPageOfSurveys = numPagOfSurveys
      // console.log('Current page of bulletin is ', this.currentPageOfSurveys)
    },
    openCreateModal () {
      this.modalData = {}
      this.isEditingSurvey = true
      this.create = true
    },
    openEditModal (survey) {
      // console.log(survey)
      this.modalData = survey
      if (survey.id) {
        this.getStats(survey.id)
          .then((r) => {
            // this.loading = false
            // console.log('bulletins loaded')
            this.isEditingSurvey = true
          }).catch((error) => {
            // this.loading = false
            console.log('error cargando pqrs', error)
          }).finally(() => {
            this.setLoading(false)
            this.loading = false
          })
      }
    },
    openCoverPicModal (pic) {
      // console.log('picture selected', pic)
      this.coverPicModalData = pic
      this.isShowingCoverPic = true
    },

    closeCoverPicModal () {
      this.isShowingCoverPic = false
      this.coverPicModalData = null
    },

    closeModal () {
      // console.log('emit close modal call')
      this.isEditingSurvey = false
      this.create = false
      this.modalData = {}
    },

    ...mapActions('surveys', [
      'loadSurveys',
      'update_Survey',
      'getStats'
    ]),

    classObjectIsImportant (isImportant) {
      return {
        importantThumbtack: isImportant,
        nonImportantThumbtack: !isImportant
      }
    },

    classObjectIsImportantRow (isImportant) {
      return {
        blue: isImportant,
        nonImportantRow: !isImportant
      }
    },

    getFirstImage (attachment) {
      if (attachment && attachment.length > 0) {
        return attachment.filter((value) => (value.includes('.jpg') || value.includes('.png')))[0]
      } else {
        return []
      }
    },

    getFirstPdf (attachment) {
      if (attachment && attachment.length > 0) {
        return attachment.filter((value) => (value.includes('.pdf')))[0]
      } else {
        return []
      }
    },

    changeActivation (survey, e) {
      // console.log('Change Open')
      const id = survey.id
      const { imageUrl, description, active, activatedAt, expiresAt, title } = survey
      const updatedSurvey = {
        id,
        imageUrl,
        description,
        activatedAt,
        expiresAt,
        active: !active,
        title
      }

      this.setLoading(true)
      changeActivationStatus(id, { active: updatedSurvey.active }).then(() => {
        // this.isEditingOnTable = false
        this.update_Survey(updatedSurvey)
      }).catch(error => {
        console.log(error)
        // this.isEditingOnTable = false
        e.target.checked = false
        this.$swal(error.response.data.message, '', 'error')
      }).finally(() => {
        this.setLoading(false)
      })
    },

    getFormattedCreatedDate (surveyactivatedAt) {
      const createdDate = new Date(surveyactivatedAt)
      let day = createdDate.getDate()
      day = day < 10 ? `0${day}` : `${day}`
      let month = createdDate.getMonth() + 1
      month = month < 10 ? `0${month}` : `${month}`
      return `${day}/${month}/${createdDate.getFullYear()}`
    },

    verifypdf (listAttachments) {
      if (listAttachments) {
        return listAttachments.reduce((pre, value) => {
          if (pre) return pre
          return (value) ? value.includes('.pdf') : pre
        }, false)
      } else {
        return false
      }
    },

    verifyImage (listAttachments) {
      if (listAttachments) {
        return listAttachments.reduce((pre, value) => {
          if (pre) return pre
          return (value) ? (value.includes('.jpg') || value.includes('.png')) : pre
        }, false)
      } else {
        return false
      }
    },

    formatFilesInfo (value) {
      // const attachmentIcon = '<i class="paperclip icon"></i>'
      const file = this.getFirstPdf(value)
      return `<a href="${file}"><i class="paperclip icon" style="color: #2185d0"></i></a>`
    }
  }
}
</script>

<style scoped>
  .descriptionField {
    /* background-color: red !important; */
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    /* max-width: inherit !important; */
    white-space: nowrap;
  }

    .view-container {
        text-align: left;
        margin-left: 30px;
        margin-top: 17px;
        /*height: 90vh;*/
    }

    .pqrsLink {
      color: #808080 !important;
    }

    .importantThumbtack, .nonImportantThumbtack, .noCoverPicture {
      color: #808080;
      font-size: 20px;
    }

    .importantThumbtack {
      color: rgb(235, 36, 36);
      /* transform: rotate(-45deg); */
      transform: rotate(0deg);
      transition: ease .3s
    }

    /* .importantThumbtack:hover {
      color: #808080;
      transform: rotate(-45deg)
    } */

    .noCoverPicture {
      color: #808080
    }

    .nonImportantThumbtack {
      color: #808080;
      transform: rotate(-45deg);
      transition: ease .3s
    }

    /*.nonImportantThumbtack:hover {
      color: rgb(235, 36, 36);
      transform: rotate(0);
    } */

    .editPen {
      font-size: 15px;
      transition: ease .3s
    }

    .editPen:hover {
      transform: scale(1.2);
    }

    .CoverPicture {
      padding: 0 !important;
    }
    .coverImage {
      height: 30px;
      width: auto
    }
    .elementInTD {
      display:flex;
      justify-content: center;
      align-items: center;
    }

    .nonImportantRow {
      color: #808080;
    }

    .nonImportantRow:hover {
      color: #808080 !important;
    }

    /* .sectionActive {
      background-color: #436f98 !important;
      color: white !important;
      transition: background-color .5s ease !important;
    }
    .sectionActive:hover {
      background-color: #2c97c5 !important;
    } */
    .create_and_changeSection_btns {
      display: flex;
      width: 100%;
      height: 60px;
      justify-content: space-between;
      align-items: center;
      padding-right: 35px;
    }

    .changeSection {
      display: flex;
      justify-content: center;
    }

    .activePage {
  background-color: #436f98 !important;
  color: white !important;
    }
</style>
